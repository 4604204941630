<template>
  <div class="tw-mx-auto tw-w-full tw-text-gray-700">
    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-8 lg:tw-space-y-0 lg:tw-space-x-8">
      <div class="tw-w-full">
        <section v-if="applyForJobStatus.isSuccess" class="tw-bg-white tw-p-4 lg:tw-p-8 tw-text-center">
          <img src="/web/projects/ok.svg" alt="finished" />
          <h2 class="tw-heading-4 tw-leading-none">{{ $t('global.titles.all_done') }}</h2>
          <p class="tw-mt-4 tw-px-12">
            {{ $t('project.detail.descriptions.interest_team_contacts_you') }}
          </p>
          <div class="tw-mt-8 tw-flex tw-justify-center">
            <k-button color="primary" class="tw-w-60" @click="handleCloseDialog">
              {{ $t('global.ok') }}
            </k-button>
          </div>
        </section>

        <section v-else class="tw-bg-white tw-p-4 lg:tw-p-8">
          <h2 class="tw-heading-4 tw-leading-none">{{ $t('project.detail.subtitles.interest_contacts') }}</h2>

          <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-gap-6 sm:tw-grid-cols-2 tw-mt-6">
            <k-input
              autofocus
              :title="$t('global.inputs.first_name')"
              required
              v-model="jobApplication.firstName"
              :invalid="!isValidField('firstName')"
            />

            <k-input
              :title="$t('global.inputs.last_name')"
              required
              v-model="jobApplication.lastName"
              :invalid="!isValidField('lastName')"
            />
          </div>

          <div class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-gap-6 sm:tw-grid-cols-2 tw-mt-6">
            <k-input
              type="email"
              :title="$t('global.inputs.email')"
              required
              v-model="jobApplication.email"
              :invalid="!isValidField('email')"
            />

            <k-phone
              ref="phone"
              :init-phone-number="profile.phone"
              @update-phone-number="jobApplication.phone = $event"
            />
          </div>

          <div class="tw-mt-8">
            <p class="tw-heading-5 tw-leading-none tw-mt-8">{{ $t('project.detail.subtitles.interest_upload_resume')
              }}</p>
            <p class="tw-mt-2">{{ $t('project.detail.descriptions.interest_recommended_upload') }}</p>

            <v-radio-group v-model="uploadCvNow" row class="tw-m-0 tw-mt-4 tw-p-0" hide-details>
              <k-radio :label="$t('global.time.now')" :value="true" />
              <k-radio :label="$t('global.time.later')" :value="false" @click="jobApplication.file = null" />
            </v-radio-group>
          </div>

          <v-expand-transition>
            <div v-show="uploadCvNow" class="tw-mt-4">
              <k-button @click="handleUploadFile()">
                <k-icon name="attach" size="20" class="tw-mr-1" />
                {{ $t('global.actions.upload_file') }}
              </k-button>

              <div v-if="jobApplication.file" class="tw-mt-4">
                <div class="tw-bg-gray-200 tw-px-2 tw-py-2 tw-rounded-sm tw-flex tw-items-center tw-justify-between">
                  <span class="tw-pl-2">{{ jobApplication.file.name }}</span>

                  <k-button @click="jobApplication.file = null">
                    <k-icon name="close" size="14" class="tw-cursor-pointer" />
                  </k-button>
                </div>
              </div>
            </div>
          </v-expand-transition>

          <v-expand-transition :class="{ 'tw-mt-': !!errorMessage }">
            <div v-show="errorMessage" class="form-error-message tw-m-0 tw-p-0">
              <p>{{ $t(errorMessage) }}</p>
            </div>
          </v-expand-transition>

          <div class="tw-mt-8">
            <k-button
              type="button"
              @click.prevent="submit"
              :loading="applyForJobStatus.isLoading"
              :disabled="applyForJobStatus.isLoading"
            >
              {{ $t('global.actions.send') }}
            </k-button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KPhone from '@web/components/core/k-phone.vue'

export default {
  components: { KPhone },
  props: ['project'],
  data: () => ({
    uploadCvNow: true,
    errorMessage: null,
    jobApplication: {
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      file: null
    }
  }),
  computed: {
    ...mapGetters({
      profile: 'USER/getUserProfile'
    }),
    applyForJobStatus() {
      return this.$store.getters['NEW_PROJECTS/getStatus']('applyForJobStatus')
    }
  },
  watch: {
    profile: {
      immediate: true,
      handler() {
        if (!this.profile) {
          return
        }

        this.jobApplication.email = this.profile.email
        this.jobApplication.firstName = this.profile.firstName
        this.jobApplication.lastName = this.profile.lastName
      }
    }
  },
  methods: {
    submit() {
      if (!this.validate()) {
        return
      }

      const {
        utm_source = null,
        utm_medium = null,
        utm_campaign = null,
        utm_recruit = null
      } = this.$route.query

      this.$store.dispatch('NEW_PROJECTS/applyForJob', {
        id: this.project.id,
        source: utm_source?.toUpperCase(),
        medium: utm_medium?.toUpperCase(),
        campaign: utm_campaign,
        recruiterId: utm_recruit,
        ...this.jobApplication
      })
    },
    validate() {
      if (!this.isValidField('firstName')) {
        this.errorMessage = 'global.form_errors.enter_name'

        return false
      }

      if (!this.isValidField('lastName')) {
        this.errorMessage = 'global.form_errors.enter_last_name'

        return false
      }

      if (!this.isValidField('email')) {
        this.errorMessage = 'global.form_errors.enter_email'

        return false
      }

      const phoneValidation = this.$refs.phone.validate()

      if (!phoneValidation.isValid) {
        this.errorMessage = phoneValidation.errorMessage

        return false
      }

      this.errorMessage = null

      return true
    },
    isValidField(fieldName) {
      switch (fieldName) {
        case 'firstName':
          return typeof this.jobApplication.firstName === 'string' && this.jobApplication.firstName.trim().length > 0
        case 'lastName':
          return typeof this.jobApplication.lastName === 'string' && this.jobApplication.lastName.trim().length > 0
        case 'email':
          return typeof this.jobApplication.email === 'string' && /(.+)@(.+){2,}\.(.+){2,}/.test(this.jobApplication.email)
      }
    },
    handleUploadFile() {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('name', 'files')
      input.setAttribute('accept', '.pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document')
      input.onchange = e => {
        this.jobApplication.file = e.target.files[0]
      }
      input.dispatchEvent(new MouseEvent('click'))
    },
    handleCloseDialog() {
      this.$store.dispatch('NEW_PROJECTS/clearStatus', 'applyForJobStatus')
      this.$emit('close')
    }
  }
}
</script>
