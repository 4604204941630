<template>
  <div class="tw-bg-white tw-shadow-glow tw-p-8 tw-text-center">
    <span class="tw-heading-5">{{ $t('project.detail.subtitles.hr_need_info') }} 🤔</span>
    <p class="tw-text-sm tw-mt-4">
      {{ $t('project.detail.descriptions.hr_contact_directly') }}
    </p>
    <div v-if="person" class="tw-mt-6">
      <div class="tw-flex tw-items-center tw-justify-between tw-max-w-sm tw-mx-auto">
        <div class="tw-flex tw-items-center">
          <img
            v-if="person.photoURL"
            class="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-bg-black tw-rounded-full tw-mr-4"
            :src="person.photoURL"
          />
          <p class="tw-text-gray-800 tw-font-bold">{{ person.name }}</p>
        </div>
        <div class="tw-flex tw-items-center tw-space-x-1">
          <a
            v-if="person.phone"
            :href="`tel:${person.phone}`"
            class="tw-inline-flex tw-w-10 tw-h-10 tw-rounded-full tw-bg-green-alt tw-items-center tw-justify-center tw-text-green hover:tw-bg-green-alt-2 tw-transition tw-duration-150 tw-no-underline"
          >
            <k-icon size="18" name="phone" class="tw-flex-shrink-0" />
          </a>
          <a
            v-if="person.email"
            :href="`mailto:${person.email}`"
            class="tw-inline-flex tw-w-10 tw-h-10 tw-rounded-full tw-bg-gray-100 tw-items-center tw-justify-center tw-text-gray-800 hover:tw-bg-gray-200 tw-transition tw-duration-150 tw-no-underline"
          >
            <k-icon size="16" name="email" class="tw-flex-shrink-0" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      default: () => ({
        photoURL: null,
        phone: null,
        email: null
      })
    }
  }
}
</script>
