<template>
  <dl class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-6">
    <div v-for="technology in computedTechnologies" :key="technology.id">
      <dt class="tw-heading-6 tw-text-gray-500">{{ technology.displayString }}</dt>
      <dd class="tw-mt-4 tw-flow-root">
        <div class="tw--mt-0.5 tw--ml-0.5">
          <k-technology-chip
            size="lg"
            v-for="stack in technology.stacks"
            :key="stack"
            :technology="stack"
            class="tw-m-1"
          />
        </div>
      </dd>
    </div>
  </dl>
</template>

<script>
export default {
  props: {
    technologies: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    computedTechnologies() {
      let typesObject = {}

      this.technologies.forEach(technology => {
        const type = technology.type.value

        if (!(type in typesObject)) {
          typesObject = {
            ...typesObject,
            [type]: {
              displayString: technology.type.displayString,
              stacks: [technology.name]
            }
          }
        } else {
          typesObject[type].stacks.push(technology.name)
        }
      })

      return typesObject
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.technologies__section {
  @media print {
    width: 100%;
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

.technologies {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: 1.5rem;

  @include lg {
    grid-template-columns: 1fr 1fr;
  }

  @media print {
    display: block;
  }

  h6 {
    padding-bottom: 0.5em;
  }
}
</style>
