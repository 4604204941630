<template>
  <k-input
    v-model="phoneNumber"
    class="phone-number"
    :title="$t('global.inputs.phone')"
    :invalid="isPhoneInvalid"
  >
    <template slot="prepend">
      <k-autocomplete
        v-model="countryPhoneCode"
        :items="countryPhoneCodes"
        item-value="phoneCode"
        item-text="phoneCode"
        return-object
      >
        <template v-slot:item="{ item }">
          {{ item.name }}: {{ item.phoneCode }}
        </template>
      </k-autocomplete>
    </template>
  </k-input>
</template>

<script>
import KAutocomplete from '@web/components/core/k-autocomplete.vue'
import { mapGetters } from 'vuex'
import phone from 'phone'

export default {
  name: 'k-phone',
  components: { KAutocomplete },
  props: {
    initPhoneNumber: String
  },
  emits: ['update-phone-number'],
  data: () => ({
    phoneNumber: null,
    countryPhoneCode: null,
    isPhoneInvalid: false
  }),
  computed: {
    ...mapGetters({
      countryPhoneCodes: 'ENUMS/getCountriesPhones',
      defaultPhoneCountry: 'ENUMS/getDefaultPhoneCountry'
    }),
    constructedPhoneNumber() {
      return `${ this.countryPhoneCode?.phoneCode }${ this.phoneNumber }`
    }
  },
  mounted() {
    if (this.countryPhoneCodes.length === 0) {
      this.$store.dispatch('ENUMS/fetchCountriesPhones')
    }
  },
  methods: {
    validate() {
      if (!this.countryPhoneCode) {
        this.isPhoneInvalid = true

        return {
          isValid: false,
          errorMessage: 'global.form_errors.select_phone_code'
        }
      }

      if (!(this.phoneNumber && String(this.phoneNumber).trim().length > 0)) {
        this.isPhoneInvalid = true

        return {
          isValid: false,
          errorMessage: 'global.form_errors.enter_phone_number'
        }
      }

      if (!phone(this.phoneNumber, { country: this.countryPhoneCode.code }).isValid) {
        this.isPhoneInvalid = true

        return {
          isValid: false,
          errorMessage: 'global.form_errors.valid_phone_number'
        }
      }

      this.isPhoneInvalid = false

      return {
        isValid: true
      }
    }
  },
  watch: {
    defaultPhoneCountry: {
      immediate: true,
      deep: true,
      handler(defaultPhone) {
        if (defaultPhone && !this.initPhoneNumber) {
          this.countryPhoneCode = defaultPhone
        }
      }
    },
    countryPhoneCodes: {
      deep: true,
      immediate: true,
      handler(countries) {
        if (this.initPhoneNumber) {
          const phoneObject = phone(this.initPhoneNumber)

          if (!phoneObject.isValid) {
            this.phoneNumber = null
            this.countryPhoneCode = this.defaultPhoneCountry

            return
          }

          const phoneCode = countries.find(cPc => cPc.code === phoneObject.countryIso3)

          this.phoneNumber = phoneObject.phoneNumber.replace(phoneObject.countryCode, '')
          this.countryPhoneCode = phoneCode || this.defaultPhoneCountry
        }
      }
    },
    constructedPhoneNumber: {
      immediate: true,
      handler(phone) {
        this.$emit('update-phone-number', phone)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.phone-number ::v-deep .v-input__prepend-outer {
  margin-top: 0 !important;
}
</style>
